import { useLazyQuery } from "@apollo/react-hooks"
import { loader } from "graphql.macro"

const gql = loader("./gql/queryMe.graphql")

export const useCurrentUser = () => {
  const [fetch, { loading, error, data }] = useLazyQuery(gql)
  // console.log("useCurrentUser", { loading, error, data })
  const response = data ? data.me.data : null
  return [{ loading, error, response }, fetch]
}
