import React, { useEffect, useContext } from "react"

import { CurrentUserContext } from "../context/currentUser"
import { useCurrentUser } from "../hooks/useCurrentUser"
import useLocalStorage from "../hooks/useLocalStorage"

const CurrentUserChecker = ({ children }) => {
  const [, dispatch] = useContext(CurrentUserContext)
  const [token] = useLocalStorage("token")
  const [{ response }, fetch] = useCurrentUser()

  useEffect(() => {
    if (!token) {
      dispatch({ type: "SET_UNAUTHORIZED" })
      return
    }

    fetch()
    dispatch({ type: "LOADING" })
  }, [token, fetch, dispatch])

  useEffect(() => {
    if (!response) return
    // console.log("CurrentUserChecker", response)
    dispatch({ type: "SET_AUTHORIZED", payload: response })
  }, [response, dispatch])

  return <>{children}</>
}

export default CurrentUserChecker
