import { InMemoryCache, HttpLink, ApolloClient } from "@apollo/client"
import fetch from "isomorphic-fetch"

let authorization = ""

if (typeof window !== "undefined") {
  const token = localStorage.getItem("token")
  authorization = token ? `Bearer ${JSON.parse(token)}` : ""
}

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.GATSBY_API_URL,
    credentials: "same-origin",
    headers: {
      authorization,
    },
    fetch,
  }),
})
