import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"

import { client } from "./client"
import { CurrentUserProvider } from "../context/currentUser"
import CurrentUserChecker from "../components/currentUserChecker"

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <CurrentUserProvider>
        <CurrentUserChecker>{element}</CurrentUserChecker>
      </CurrentUserProvider>
    </ApolloProvider>
  )
}
