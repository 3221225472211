import { useState, useEffect, useCallback } from "react";

const useLocalStorage = (key, initialValue = "") => {
  const [initialized, setInitialized] = useState(false);
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [value, setValue] = useState(initialValue);

  const getValue = useCallback(() => {
    const localValue = localStorage.getItem(key);
    if (localValue) {
      try {
        return JSON.parse(localValue);
      } catch (error) {
        localStorage.removeItem(key);
      }
    }

    return initialValue;
  }, [initialValue, key]);

  useEffect(() => {
    if (!windowLoaded) {
      if (typeof window !== "undefined" && window) {
        setWindowLoaded(true);
      }
    }
  }, [windowLoaded]);

  useEffect(() => {
    if (!initialized && windowLoaded) {
      const localValue = getValue();
      if (!value === localValue) return;
      setValue(localValue);
      setInitialized(true);
    }
  }, [initialized, windowLoaded]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage